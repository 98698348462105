import _ from "lodash"

export async function downloadFile(data: string, fileName: string, fileType: string) {
    let blob
    // Create a blob with the data we want to download as a file
    if (fileType === "image") {
        blob = await (await fetch(data)).blob()
    } else {
        blob = new Blob([data], { type: fileType })
    }
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement("a")
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
}

export function extractQueryTerms(text: string) {
    return (text.toLowerCase().match(/\b\w+\b/g) || []).map((term) => new RegExp(`\\b${term}`))
}
export function matchScore(text: string, queryTerms: RegExp[]): number {
    let score = 0
    for (const term of queryTerms) {
        const termIndex = text.search(term)
        if (termIndex == -1) return -1
        score += termIndex
    }
    return score
}
export function extractText(item: any): string {
    if (!item) {
        return ""
    } else if (typeof item === "string") {
        return item.toLowerCase()
    } else if (Array.isArray(item)) {
        return item.map(extractText).join(" ")
    }
    const props = Object.keys(item).map((key) => item[key])
    return props.map(extractText).join(" ")
}

export function filterItems(items: any[], query: string, fields?: string[]): any[] {
    if (!query || !items.length) return items

    const queryTerms = extractQueryTerms(query)
    const scores = items.map((item) =>
        matchScore(extractText(fields ? _.pick(items, fields) : item), queryTerms),
    )

    return items.filter((item, index) => scores[index] > -1)
}

export function titlecase(text: string): string {
    text = text.trim().replace(/\s+/g, " ") // Strip spaces and replace multiple spaces with one.
    return text.replace(/\b\w+/g, (match) => {
        return match === match.toUpperCase() ? match : capitalizeFirstLetter(match)
    })
}

export function capitalizeFirstLetter(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1)
}

export function displayFormatter(
    num: number | undefined,
    unit?: string,
    precision?: number,
    prefix?: boolean,
) {
    if (num === undefined || num == null || isNaN(num)) return ""
    const numStr = Number.isInteger(Number(num))
        ? Intl.NumberFormat("en-US").format(num)
        : Intl.NumberFormat("en-US", {
              maximumFractionDigits: precision ?? 2,
              minimumFractionDigits: precision ?? 2,
          }).format(num)
    return unit ? addUnit(numStr, unit, prefix) : numStr
}

export function displayCompact(
    num: number | undefined,
    unit?: string,
    precision?: number,
    prefix?: boolean,
) {
    if (num === undefined || num == null || isNaN(num)) return ""
    let numStr = ""
    if (Math.abs(num) >= 1e12) numStr = displayFormatter(num / 1e12, "tn", precision)
    else if (Math.abs(num) >= 1e9) numStr = displayFormatter(num / 1e9, "bn", precision)
    else if (Math.abs(num) >= 1e6) numStr = displayFormatter(num / 1e6, "mn", precision)
    else numStr = displayFormatter(num, "", precision)
    return unit ? addUnit(numStr, unit, prefix) : numStr
}

export function addUnit(numStr: string, unit?: string, prefix?: boolean) {
    return prefix ? `${unit}${numStr}` : `${numStr}${unit}`
}

export function buildUnit(unit?: string, prefix?: boolean, addSpace?: boolean) {
    if (!unit) return ""
    const space = addSpace ? " " : ""
    return prefix ? `${unit}${space}` : `${space}${unit}`
}

export function dataLabelFormatter(
    v: any,
    unit: string,
    axis: { precision?: number; prefix?: boolean },
) {
    return displayCompact(v, unit, axis.precision, axis.prefix)
}

export function isAlphaNumeric(str: string) {
    return /^[a-zA-Z0-9]+$/.test(str)
}

export function hideElements(doc: Document) {
    const elements = doc.querySelectorAll(".hide-in-image")
    elements.forEach((element) => {
        const el = element as HTMLElement
        el.style.visibility = "hidden"
    })
}
