import _ from "lodash"
import React, { useState } from "react"
import ScrollMarker from "./scroll-marker"

interface LazyListProps<T> {
    items: T[]
    ItemComponent: React.FC<T>
    start?: number
    size?: number
    visible?: boolean
    marker?: "default" | "table"
    scrollRef?: React.RefObject<HTMLElement>
}

const LazyList: React.FC<LazyListProps<any>> = (props: LazyListProps<any>) => {
    const {
        items,
        ItemComponent,
        start = 0,
        size = 30,
        visible = true,
        marker = "default",
        scrollRef,
    } = props
    const [loaded, setLoaded] = useState(visible)

    if (start >= items.length) {
        return null
    }

    return loaded ? (
        <>
            {_.range(start, Math.min(start + size, items.length)).map((index) => (
                <ItemComponent key={index} {...items[index]} />
            ))}
            <LazyList {...props} start={start + size} visible={false} />
        </>
    ) : (
        <ScrollMarker scrollRef={scrollRef} onScrollPast={() => setLoaded(true)} marker={marker} />
    )
}

export default LazyList
