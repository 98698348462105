import { shallowEqual } from "@mantine/hooks"
import { useMemo, useRef } from "react"

function shallowCompare(prevValue?: React.DependencyList | null, currValue?: React.DependencyList) {
    if (!prevValue || !currValue) {
        return false
    }

    if (prevValue === currValue) {
        return true
    }

    if (prevValue.length !== currValue.length) {
        return false
    }

    for (let i = 0; i < prevValue.length; i += 1) {
        if (!shallowEqual(prevValue[i], currValue[i])) {
            return false
        }
    }

    return true
}

export function useShallowDeps(dependencies?: React.DependencyList) {
    const ref = useRef<React.DependencyList | null | undefined>([])
    const updateRef = useRef<number>(0)

    if (!shallowCompare(ref.current, dependencies)) {
        ref.current = dependencies
        updateRef.current += 1
    }

    return [updateRef.current]
}

// Use shallow memo hook works like useMemo but with shallow comparison of dependencies
export function useShallowMemo<T>(callback: () => T, dependencies?: React.DependencyList) {
    return useMemo(callback, useShallowDeps(dependencies))
}
