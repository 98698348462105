import { Flex, Text, useMantineTheme } from "@mantine/core"
import { IconAlertCircle } from "@tabler/icons-react"

const ChartError: React.FC<{ title?: string; errorMessage: string }> = ({
    title = "Error",
    errorMessage,
}) => {
    const theme = useMantineTheme()
    const style: React.CSSProperties = {
        border: `1px solid ${theme.colors.gray[3]}`,
        borderRadius: 5,
        textAlign: "center",
        padding: "1rem",
        fontSize: "14px",
    }
    return (
        <Flex style={style} direction="column" align="center">
            <IconAlertCircle size="2rem" color="#de5757" />
            <Text component="h1" fz={20}>
                {title}
            </Text>
            <Text component="p">{errorMessage}</Text>
            <Text component="p">
                To report this error, write to{" "}
                <a style={{ color: "#de5757" }} href="mailto:info@dataforindia.com">
                    info@dataforindia.com
                </a>
            </Text>
        </Flex>
    )
}

export default ChartError
