import * as colors from "../colors"
import { ChartType } from "../config"
import { LABEL_FONT_SIZE, LABEL_PADDING } from "../utils"
import { highlightLegendData, resetChart } from "./hover"

interface Label {
    x: number
    y: number
    label: string[]
    color: string
    bounds: [number, number]
    isOverlap: boolean
    opacity: number
    index: number
}

function stackLabelGroup(labelGroup: Label[], y: number): Label[] {
    let currentY = y
    // update y values of labels in the group
    labelGroup.forEach((item) => {
        const labelHeight = item.label.length * LABEL_FONT_SIZE
        item.y = currentY
        currentY += labelHeight + LABEL_PADDING
        item.bounds = [item.y, currentY]
    })
    return labelGroup
}

function stackLabels(chart: any) {
    const { chartArea } = chart
    let hasOverlap: boolean
    const groups: Label[][] = [...chart.$datalabels.labels]
        .sort((a: Label, b: Label) => a.y - b.y || b.index - a.index) // when both values are equal, sort by index to reverse the original order. This is to ensure stacked chart labels are in the same order as lines.
        .map((label: Label) => [label])

    let overflow = false
    do {
        hasOverlap = false
        // starting from top-most label, check for overlaps and stack labels
        for (let i = 0; i < groups.length - 1; i++) {
            const topGroup = groups[i]
            const bottomGroup = groups[i + 1]
            const topBounds = groupBounds(topGroup)
            const bottomBounds = groupBounds(bottomGroup)

            if (intersects(topBounds, bottomBounds)) {
                //move top bounds up by overlap height
                const overlapHeight = topBounds[1] - bottomBounds[0]
                const targetY = topBounds[0] - overlapHeight

                const newHeight = bottomBounds[0] - targetY
                const overflowTop = Math.max(chartArea.top - targetY, 0)
                const overflowBottom = Math.max(targetY + newHeight - chartArea.bottom, 0)

                const newY = targetY + overflowTop - overflowBottom

                // stop stacking when there is no more space left
                if (newY < chartArea.top || newY + newHeight > chartArea.bottom) {
                    overflow = true
                    break
                }

                // combine top and bottom groups and stack them
                const newGroup = [...topGroup, ...bottomGroup]
                stackLabelGroup(newGroup, newY)
                groups.splice(i, 2, newGroup)
                hasOverlap = true
                break
            }
        }
    } while (!overflow && hasOverlap)
}

function markOverlaps(chart: any) {
    const labels = chart.$datalabels.labels
    for (let i = 0; i < labels.length; i++) {
        const l1 = labels[i]

        for (let j = i + 1; j < labels.length; j++) {
            const l2 = labels[j]
            const isOverlap = !l1.isOverlap && intersects(l1.bounds, l2.bounds)
            if (isOverlap) {
                l2.isOverlap = true
                l2.color = colors.desaturate(l2.color)
                l2.opacity = 0.2
            }
        }
    }
}

function groupBounds(labelGroup: Label[]): [number, number] {
    const first = labelGroup[0]
    const last = labelGroup[labelGroup.length - 1]
    return [first.bounds[0], last.bounds[1]]
}

function intersects(label1: [number, number], label2: [number, number]) {
    // label2[0] is between label1 bounds
    return (
        (label1[0] <= label2[0] && label2[0] < label1[1]) ||
        (label2[0] <= label1[0] && label1[0] < label2[1])
    )
}

function drawLabel(ctx: CanvasRenderingContext2D, label: string[], x: number, y: number) {
    // Draw each item of the label array on a new line
    label.forEach((line: string, i: number) => {
        ctx.fillText(line, x, y + i * LABEL_FONT_SIZE)
    })
}

export const datalabels = {
    id: "datalabels",
    beforeUpdate: (chart: any) => {
        chart.$datalabels = {
            labels: [],
        }
    },
    afterDatasetsUpdate: (chart: any, args: any, options: any) => {
        chart.$datalabels.labels = []
        if (!options.display) return

        for (let i = 0; i < chart.data.datasets.length; i++) {
            const dataset = chart.data.datasets[i]
            const meta = chart.getDatasetMeta(i)
            const element = meta.data.length ? meta.data[meta.data.length - 1] : null // Get the last data point of the line

            const lastValue = dataset.data[dataset.data.length - 1]

            let label = options.formatter(dataset.label)
            const stacked = dataset.fill
            if (!Array.isArray(label)) label = [label]
            const labelHeight = label.length * LABEL_FONT_SIZE

            // Position the label to the right of the line's last point
            const x = chart.chartArea.right + 5
            if (element) {
                let y = element.y + 3

                // move the label into the filled area for stacked lines
                y += stacked ? (lastValue.y >= 0 ? 5 : -10) : 0

                chart.$datalabels.labels.push({
                    x,
                    y,
                    label,
                    color: dataset.borderColor,
                    bounds: [y, y + labelHeight],
                    isOverlap: false,
                    opacity: 0.9,
                    index: i,
                })
            }
        }
        stackLabels(chart)
        markOverlaps(chart)
    },
    afterDatasetsDraw: (chart: any, args: any, options: any) => {
        if (!options.display) return
        drawLabels(chart)
    },
    beforeEvent: (chart: any, args: any) => {
        const { event } = args
        // ignore mouse events in the chart area
        if (event.x <= chart.chartArea.right) return

        const label = lookup(chart.$datalabels.labels, event.y)
        if (label) {
            highlightLegendData(label.index, ChartType.LineChart)
        } else {
            resetChart(ChartType.LineChart)
        }
    },
}

function lookup(labels: Label[], eventY: number) {
    for (const label of labels) {
        if (eventY + 10 >= label.bounds[0] && eventY + 10 <= label.bounds[1]) {
            return label
        }
    }
}

export function drawLabels(chart: any) {
    const { ctx } = chart

    const labels = chart.$datalabels.labels

    labels.forEach((label: Label) => {
        ctx.save()

        ctx.textAlign = "left"
        ctx.fillStyle = label.color
        ctx.globalAlpha = label.opacity

        drawLabel(ctx, label.label, label.x, label.y)

        ctx.restore()
    })
}
