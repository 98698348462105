import { UseFormReturnType } from "@mantine/form"
import React, { PropsWithChildren } from "react"
import { dt } from "../../../dfi-utils"
import { DFIChartConfig } from "../config"

export type DFIChartConfigForm = UseFormReturnType<DFIChartConfig>
export interface DFIChartContextType {
    table: dt.DFIDataTable
    form: DFIChartConfigForm
}

const DFIChartContext = React.createContext<DFIChartContextType | null>(null)

function useChart() {
    const config = React.useContext(DFIChartContext)
    if (!config) {
        throw new Error("Wrap component in <DFIChartConfigProvider>")
    }
    return config
}

const DFIChartConfigProvider: React.FC<PropsWithChildren<DFIChartContextType>> = ({
    children,
    table,
    form,
}) => {
    return <DFIChartContext.Provider value={{ table, form }}>{children}</DFIChartContext.Provider>
}

export { DFIChartConfigProvider, useChart }
