import { Box, Text } from "@mantine/core"
import _ from "lodash"
import React from "react"
import { LazyList } from "../.."

interface SearchResultsProps {
    items: any[]
    resultIndices?: number[] | null
    allIndices?: number[] | null
    itemComponent: React.FC<any>
}

const SearchResults: React.FC<SearchResultsProps> = ({
    items,
    resultIndices,
    allIndices,
    itemComponent,
}: SearchResultsProps) => {
    const filteredItems = React.useMemo(
        () =>
            resultIndices
                ? _.at(items, resultIndices)
                : allIndices
                ? _.at(items, allIndices)
                : items,
        [items, resultIndices, allIndices],
    )

    return (
        <Box pos="relative">
            {resultIndices ? (
                <Text mb="sm" c="gray" fz="sm">
                    Found {filteredItems.length} items
                </Text>
            ) : null}
            {items.length > 0 ? (
                <LazyList items={filteredItems} ItemComponent={itemComponent} />
            ) : (
                <div>Did not find any matching items</div>
            )}
        </Box>
    )
}

export default SearchResults
