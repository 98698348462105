import { ActionIcon, Box, Group, Stack, Table, Text, Tooltip } from "@mantine/core"
import { useClipboard } from "@mantine/hooks"
import { IconCopy, IconDownload } from "@tabler/icons-react"
import _ from "lodash"
import React, { useMemo } from "react"
import { LazyList, utils } from "../.."
import * as dt from "../lib/dfi-data-table"

interface DataTableProps {
    options?: {
        hideTitle?: boolean
        hideOptions?: boolean
        formatter?: Record<string, ((v: any) => string) | null>
    }
    table: dt.DFIDataTable
}

const DataTable: React.FC<DataTableProps> = ({ table, options }) => {
    const { data, index, title, num_rows } = table
    const clipboard = useClipboard()

    const colcodes = Object.keys(data)
    const indexcodes = new Set(index)
    const rowObjects = useMemo(() => _.range(num_rows).map((i) => ({ row: i })), [num_rows])

    const header = colcodes.map((code: string, i: number) => (
        <th key={i} className={indexcodes.has(code) ? "index" : ""}>
            {dt.getLabel(table, code)}
        </th>
    ))
    const RowComponent: React.FC<{ row: number }> = ({ row }) => {
        return (
            <tr>
                {colcodes.map((code: string, j: number) => {
                    const formatter = options?.formatter?.[code]
                    const cell = dt.getValueLabel(table, code, data[code][row])
                    const className = indexcodes.has(code) ? "index" : ""

                    return isNaN(cell) || formatter === null ? (
                        <td key={j} className={className}>
                            {cell}
                        </td>
                    ) : (
                        <td key={j} className={className} style={{ textAlign: "right" }}>
                            {formatter ? formatter(cell) : utils.displayFormatter(cell)}
                        </td>
                    )
                })}
            </tr>
        )
    }

    return (
        <Stack>
            <Box>
                {!options?.hideTitle && title && <Text fz="lg" fw={500}>{`${title}`}</Text>}
                {!options?.hideOptions && (
                    <Group spacing="xs">
                        <Text>Showing {num_rows} rows</Text>
                        <Tooltip label="Download as CSV">
                            <ActionIcon
                                variant="light"
                                onClick={() =>
                                    utils.downloadFile(dt.toCsv(table), "table-data.csv", "csv")
                                }
                                size="sm"
                            >
                                <IconDownload size="1em" />
                            </ActionIcon>
                        </Tooltip>
                        <Tooltip label={clipboard.copied ? "Copied!" : "Copy to clipboard"}>
                            <ActionIcon
                                variant="light"
                                onClick={() => clipboard.copy(dt.toCsv(table))}
                                c={clipboard.copied ? "green" : "gray"}
                                size="sm"
                            >
                                <IconCopy size="1em" />
                            </ActionIcon>
                        </Tooltip>
                    </Group>
                )}
            </Box>
            <Table striped highlightOnHover withBorder withColumnBorders className="fit-content">
                <thead>
                    <tr>{header}</tr>
                </thead>
                <tbody>
                    <LazyList items={rowObjects} ItemComponent={RowComponent} marker="table" />
                </tbody>
            </Table>
        </Stack>
    )
}

export default DataTable
