export function getLabelPoint(color: string) {
    const canvas = document.createElement("canvas")
    canvas.width = 10
    canvas.height = 10
    const ctx = canvas.getContext("2d")
    if (!ctx) return

    ctx.strokeStyle = color
    ctx.lineWidth = 1.5

    const numberOfLines = 2
    const spacing = canvas.height / numberOfLines

    // Draw diagonal lines in the bottom half of the canvas
    for (let i = 0; i < numberOfLines; i++) {
        ctx.beginPath()
        ctx.moveTo(0, (i + 1) * spacing)
        ctx.lineTo(canvas.width, (i + 1) * spacing + canvas.width)
        ctx.stroke()
    }

    // Draw a diagonal line from the top left to the bottom right
    ctx.beginPath()
    ctx.moveTo(0, 0)
    ctx.lineTo(canvas.width, canvas.height)
    ctx.stroke()

    // Draw diagonal lines in the top half of the canvas
    for (let i = 0; i < numberOfLines; i++) {
        ctx.beginPath()
        ctx.moveTo((i + 1) * spacing, 0)
        ctx.lineTo((i + 1) * spacing + canvas.height, canvas.height)
        ctx.stroke()
    }

    return ctx.createPattern(canvas, "repeat")
}
