import { useIntersection } from "@mantine/hooks"
import React, { useEffect } from "react"

interface ScrollMarkerProps {
    onScrollPast: () => void
    scrollRef?: React.RefObject<HTMLElement>
    marker?: "default" | "table"
}

const ScrollMarker: React.FC<ScrollMarkerProps> = ({ onScrollPast, scrollRef, marker }) => {
    const { ref, entry } = useIntersection({
        root: scrollRef?.current ?? null,
        rootMargin: "100px",
        threshold: 0,
    })

    useEffect(() => {
        entry?.isIntersecting && onScrollPast()
    }, [entry?.isIntersecting])

    return marker === "table" ? (
        <tr style={{ height: "100px" }} ref={ref} />
    ) : (
        <div style={{ height: "100px" }} ref={ref} />
    )
}

export default ScrollMarker
