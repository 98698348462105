import { Box } from "@mantine/core"
import { useForm } from "@mantine/form"
import { useRef, useState } from "react"
import { dt } from "../../../dfi-utils"
import { DFIChartConfig } from "../config"
import DFIChart from "./chart"
import { DataContextProvider } from "./chart-data-context"
import { DFIChartConfigProvider } from "./context"

export interface DFIChartDisplayProps {
    table: dt.DFIDataTable
    config?: DFIChartConfig
    adminControls?: boolean
}

export const DFIChartDisplay: React.FC<DFIChartDisplayProps> = ({
    table,
    config,
    adminControls,
}) => {
    const form = useForm<DFIChartConfig>({
        initialValues: config,
    })
    const containerRef = useRef<HTMLDivElement>(null)
    const [renderClone, setRenderClone] = useState(false)

    return (
        <DFIChartConfigProvider form={form} table={table}>
            <DataContextProvider>
                <DFIChart
                    adminControls={adminControls}
                    containerRef={containerRef}
                    setRenderClone={setRenderClone}
                />
                {renderClone && (
                    <Box ref={containerRef} mt={-3500}>
                        <DFIChart clone />
                    </Box>
                )}
            </DataContextProvider>
        </DFIChartConfigProvider>
    )
}
