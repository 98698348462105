import tinycolor from "tinycolor2"

export function interpolateColor(colors: string[], factor: number, categorical?: boolean) {
    factor = Math.min(Math.max(factor, 0), 1.05)

    const position = (colors.length - 1) * factor
    const color_bin = Math.floor(position)

    if (categorical) {
        return colors[color_bin] ?? colors[0]
    }

    const factorNormalized = position - color_bin

    const c1 = tinycolor(colors[color_bin])
    const c2 = tinycolor(colors[color_bin + 1])

    const r = interpolate(c1.toRgb().r, c2.toRgb().r, factorNormalized)
    const g = interpolate(c1.toRgb().g, c2.toRgb().g, factorNormalized)
    const b = interpolate(c1.toRgb().b, c2.toRgb().b, factorNormalized)

    return tinycolor({ r, g, b }).toHexString()
}

function interpolate(start: number, end: number, factor: number) {
    return Math.round(start + (end - start) * factor)
}

export function updateOpacity(color: string, opacity: number = 0.9) {
    return tinycolor(color).setAlpha(opacity).toHex8String()
}

export function brightness(color: string) {
    return tinycolor(color).getBrightness()
}

export function isBright(color: string) {
    return brightness(color) > 150
}

export function desaturate(color: string) {
    const hsl = tinycolor(color).toHsl()
    hsl.s = 0.15
    hsl.l = 0.9
    return tinycolor(hsl).toHexString()
}
