import { Flex, Grid } from "@mantine/core"

export interface LegendData {
    label: string
    color: string
}

interface LegendProps {
    data: LegendData[]
    onHover?: (index: number) => void
    onMouseOut?: () => void
}

const DFILegend = ({ data, onHover, onMouseOut, ...others }: LegendProps) => {
    return (
        <Grid fz={11} justify="center" gutter={10} {...others}>
            {data.map(({ label, color }: LegendData, index: number) => (
                <Grid.Col
                    key={`legend-${index}`}
                    span="content"
                    py={0}
                    onMouseMove={() => {
                        onHover && onHover(index)
                    }}
                    onPointerLeave={() => {
                        onMouseOut && onMouseOut()
                    }}
                    style={{ cursor: "pointer" }}
                >
                    <Flex align="center">
                        <div
                            style={{
                                height: "10px",
                                width: "10px",
                                borderRadius: 2,
                                marginRight: "3px",
                                background: color,
                            }}
                        ></div>
                        <span>{label}</span>
                    </Flex>
                </Grid.Col>
            ))}
        </Grid>
    )
}

export default DFILegend
