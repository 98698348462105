import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd"
import { createStyles } from "@mantine/core"
import { useListState } from "@mantine/hooks"
import { IconGripVertical } from "@tabler/icons-react"
import cx from "clsx"

interface DndListProps<T> {
    listId: string
    data: T[]
    ItemComponent: React.FC<T>
    defaultOrder: number[]
    onReorder: (from: number, to: number) => void
}

const useStyles = createStyles((theme) => ({
    item: {
        display: "flex",
        alignItems: "center",
        borderRadius: theme.radius.md,
        width: "100%",
    },
    itemDragging: {
        boxShadow: theme.shadows.sm,
    },
    dragHandle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        padding: theme.spacing.md,
        cursor: "grab",
    },
}))

const DnDList = ({ listId, data, ItemComponent, defaultOrder, onReorder }: DndListProps<any>) => {
    const [state, handlers] = useListState(data)
    const { classes } = useStyles()

    // TODO: make this a lazy list
    const items = state.map((item, index) => (
        <Draggable key={index} index={index} draggableId={`${index}`}>
            {(provided, snapshot) => (
                <div
                    className={cx(classes.item, { [classes.itemDragging]: snapshot.isDragging })}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                >
                    <div {...provided.dragHandleProps}>
                        <IconGripVertical size="1rem" />
                    </div>
                    <div style={{ width: "100%" }}>
                        <ItemComponent {...data[defaultOrder?.[index] ?? index]} />
                    </div>
                </div>
            )}
        </Draggable>
    ))
    return (
        <DragDropContext
            key={data.length}
            onDragEnd={({ destination, source }) => {
                onReorder(source.index, destination?.index || 0)
                handlers.reorder({ from: source.index, to: destination?.index || 0 })
            }}
        >
            <Droppable droppableId={listId} direction="vertical">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {items}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default DnDList
