import _ from "lodash"
import { useEffect, useMemo, useState } from "react"
import { extractQueryTerms, extractText, matchScore } from "../lib/utils"

export function useSearchScores(
    items: any[] | undefined | null,
    query: string,
    fields?: string[],
): number[] | null {
    const [searchIndex, setSearchIndex] = useState<string[] | null>(null)
    const indices = useMemo(() => _.range(items?.length || 0), [items?.length])
    const valid = items?.length && query.length >= 2

    const scores = useMemo(() => {
        if (!valid || !searchIndex) {
            return null
        }

        const searchTerms = extractQueryTerms(query)
        return indices.map((index) => matchScore(searchIndex[index], searchTerms))
    }, [items, query, valid, searchIndex])

    // We use an effect here because we don't want to block rendering
    // and will show the loading indicator while we're building the index
    useEffect(() => {
        if (items?.length) {
            setSearchIndex(
                fields
                    ? items.map((item) => extractText(_.pick(item, fields)))
                    : items.map(extractText),
            )
        } else if (searchIndex) {
            setSearchIndex(null)
        }
    }, [items])
    // TODO: used to depend on fields, but that results in
    // an infinite loop when the fields array is constructed
    // in the call to useListSearch itself resulting in a new
    // reference on every render. Determine how react hooks
    // generally handle this case and if there's a better way

    return scores
}

function useListSearch(
    items: any[] | undefined | null,
    query: string,
    fields?: string[],
): number[] | null {
    const scores = useSearchScores(items, query, fields)

    // return indices by ascending score after filtering out non-matches
    return useMemo(
        () =>
            scores
                ? _.range(scores.length)
                      .filter((i) => scores[i] >= 0)
                      .sort((a, b) => scores[a] - scores[b])
                : null,
        [scores],
    )
}

export default useListSearch
