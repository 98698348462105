import { MantineProvider } from "@mantine/core"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { ChartDisplay } from "./chart-display"
import { dfiTheme } from "./dfi-utils"
import "./dfi-utils/src/common.css"

function App() {
    return (
        <div className="App">
            <MantineProvider withGlobalStyles withNormalizeCSS theme={dfiTheme}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/charts/:chartid" element={<ChartDisplay />} />
                        <Route path="*" element={<h1>Error 404: page not found</h1>} />
                    </Routes>
                </BrowserRouter>
            </MantineProvider>
        </div>
    )
}

export default App
