import { MantineThemeOverride } from "@mantine/core"

const lineInputLabel = {
    fontWeight: 500,
    color: "#5C5F66",
    fontSize: "11px",
}

const dfiTheme: MantineThemeOverride = {
    fontFamily: "Roboto, sans-serif",
    fontFamilyMonospace: "Roboto-Mono, monospace",

    fontSizes: {
        xxs: "8px",
        xs: "9px",
        sm: "11px",
        md: "12px",
        lg: "14px",
        xl: "16px",
        xxl: "20px",
    },

    black: "#333",
    components: {
        Input: {
            styles: (theme, params, { variant }) => ({
                input: {
                    borderColor: variant === "default" ? theme.colors.gray[3] : undefined,
                },
            }),
            variants: {
                line: (theme) => ({
                    input: {
                        border: "none",
                        outline: "none",
                        height: "2rem",
                    },
                }),
            },
        },
        InputWrapper: {
            variants: {
                line: (theme) => ({
                    label: lineInputLabel,
                    root: {
                        borderBottom: `1px solid ${theme.colors.gray[3]}`,
                        ":focus-within": {
                            borderColor: theme.colors.blue[5],
                        },
                    },
                }),
            },
        },

        MultiSelect: {
            variants: {
                line: (theme) => ({
                    input: {
                        height: "auto",
                    },
                }),
            },
        },

        Button: {
            variants: {
                line: (theme) => ({
                    root: {
                        fontWeight: "normal",
                        fontSize: "1em",
                        padding: "0",
                        borderRadius: 0,
                        color: `${theme.colors.blue[5]}`,
                        borderBottom: `1px dotted ${theme.colors.blue[5]}`,
                    },
                }),
            },
        },
    },
    other: {
        lineInputLabel,
    },
}

export default dfiTheme
