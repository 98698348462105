import { ColorSwatch, SimpleGrid } from "@mantine/core"
import { IconCheck } from "@tabler/icons-react"
import _ from "lodash"
import * as colors from "../colors"
import { colorPalette } from "../utils"

const baseColors = colorPalette.extended

const lightColors = _.range(6, 1, -1).map((i) =>
    baseColors.map((c) => colors.interpolateColor([c, "#fff"], i / 10)),
)

const darkColors = _.range(1, 6).map((i) =>
    baseColors.map((c) => colors.interpolateColor([c, "#000"], i / 20)),
)
const allColors = [...lightColors, ...baseColors, ...darkColors].flat()

const DFIColorPicker = ({
    value,
    onChange,
}: {
    value: string
    onChange: (val: string) => void
}) => {
    return (
        <SimpleGrid cols={baseColors.length} spacing={5}>
            {allColors.map((c) => (
                <ColorSwatch
                    key={c}
                    color={c}
                    size={18}
                    radius="xs"
                    onClick={() => {
                        onChange(c)
                    }}
                    style={{ cursor: "pointer" }}
                >
                    {c === value && (
                        <IconCheck size="1rem" color={colors.isBright(c) ? "#555" : "#fff"} />
                    )}
                </ColorSwatch>
            ))}
        </SimpleGrid>
    )
}

export default DFIColorPicker
