import { titlecase } from "./lib/utils"

export interface Formula<T = any> {
    fn: string
    args: T
}

export interface NamedObject {
    label?: string
    description?: string
    tags?: string[]
}

export interface DFINamedObject extends NamedObject {
    code: string
    references?: string[]
    formula?: Formula
}

export interface Column extends DFINamedObject {
    type: string
    unit?: string
    formula?: Formula
    stats?: any
}

export type DFINamedObjectUnion = Column | Table | Dataset | Project

export interface Table extends DFINamedObject {
    weight_colcode?: string
    num_rows?: number
    primary_keys?: string[]
}

export interface Dataset extends DFINamedObject {
    source_url?: string
}
export interface Project extends DFINamedObject {}

export function displaylabel(obj: DFINode) {
    return titlecase(obj.data.label ?? obj.data.code)
}

export function makeSelectOption(node: DFINode, others?: any) {
    return { value: node.data.code, label: node.data.label ?? node.data.code, node, ...others }
}

export interface DataQueryArgs {
    target: string
    index: string[]
    filter?: { expression: string } | FilterColumn[]
    sort?: boolean
}

export interface DataAggregateArgs extends DataQueryArgs {
    weight?: string
    select: SelectColumnAgg[]
}

export interface DataSelectArgs extends DataQueryArgs {
    select: string[]
}

export interface DataAggregateFormula extends Formula<DataAggregateArgs> {
    fn: "aggregate"
}

export interface DataSelectFormula extends Formula<DataSelectArgs> {
    fn: "select"
}

export type DataQueryFormula = DataAggregateFormula | DataSelectFormula

export interface SelectColumnAgg {
    code: string
    agg: string
}

export interface FilterColumn {
    code: string
    exclude?: boolean
}

export interface BasicFormulaArgs {
    col: string
    arg?: number | string
}

export interface EvalArgs {
    expression: string
    column_references: string[]
}

export interface ValueLabels {
    labels: { [key: string]: string }
}

export interface DFINode {
    path: string
    data: DFINamedObject
    parent?: DFINode
}

export interface ColumnNode extends DFINode {
    data: Column
    value_labels?: ValueLabels
}

export interface TableData {
    [colcode: string]: any[]
}

export interface TableNode extends DFINode {
    data: Table
    columns: ColumnNode[]
    columns_map: { [colcode: string]: ColumnNode }
    dataframe: TableData
}

export interface DatasetNode extends DFINode {
    data: Dataset
}

export interface ProjectNode extends DFINode {
    data: Project
}

export function tableNodeAddColumnMap(table: TableNode) {
    table.columns_map = table.columns?.reduce((map: any, colnode: ColumnNode) => {
        map[colnode.data.code] = colnode
        return map
    }, {})
}

export function columnType(column: ColumnNode) {
    const { type, stats } = column.data

    if (!type) return

    if (
        (type.startsWith("int") || type.startsWith("uint")) &&
        stats &&
        stats.min == 0 &&
        stats.max == 1
    ) {
        return "bool"
    }
    if (type.startsWith("int") || type.startsWith("uint")) {
        return "int"
    }
    if (["float", "double"].includes(type)) {
        return "float"
    }
}

export function isColumnNumeric(column: ColumnNode) {
    const type = columnType(column)
    if (!type) return false
    return ["int", "float"].includes(type)
}

export const recordColumn: ColumnNode = {
    path: "",
    data: {
        code: "_record",
        label: "Record",
        type: "int8",
        stats: { min: 1, max: 1, num_nulls: 0, num_distinct: 1, mean: 1 },
    },
}

export const delimiter = encodeURI(" ")
