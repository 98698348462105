import { CloseButton, TextInput, TextInputProps, useMantineTheme } from "@mantine/core"
import { IconSearch } from "@tabler/icons-react"
import React, { useRef } from "react"

// Support for controlled components only
const SearchInput = React.forwardRef<
    HTMLInputElement,
    TextInputProps & { onChange?: (v: string) => void }
>(({ value, onChange, ...others }, fref) => {
    const theme = useMantineTheme()
    const ref = useRef<HTMLInputElement>(null)

    return (
        <TextInput
            ref={fref || ref}
            variant="line"
            value={value}
            onChange={onChange}
            rightSection={
                !!value ? (
                    <CloseButton
                        size="xs"
                        onClick={() => {
                            if (ref.current) {
                                ref.current.focus()
                                ref.current.value = ""
                                onChange?.("")
                            }
                        }}
                    />
                ) : (
                    <IconSearch size="1em" color={theme.colors.gray[5]} />
                )
            }
            {...others}
        />
    )
})

export default SearchInput
