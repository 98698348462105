import { Anchor, Box, BoxProps, Checkbox, Flex, Group, Radio } from "@mantine/core"
import { useInputState } from "@mantine/hooks"
import _ from "lodash"
import React from "react"
import { SearchInput, SearchResults, useListSearch, useShallowMemo } from "../../../dfi-utils"

interface SearchSelectProps<T> {
    items: T[]
    value: string[]
    onChange: (values: string[]) => void
    placeholder?: string
    singleSelect?: boolean
    clearOnSelect?: boolean
    searchThreshold?: number
}

const SearchSelect: React.FC<BoxProps & SearchSelectProps<any>> = ({
    items,
    value,
    onChange,
    placeholder,
    singleSelect,
    clearOnSelect,
    searchThreshold,
    ...others
}) => {
    const [query, setQuery] = useInputState("")
    const itemResultIndices = useListSearch(items, query)
    const showSearch = items.length > (searchThreshold ?? 0)

    // sort the itemResultIndices by checking against values
    const selectedFirstIndices = useShallowMemo(() => {
        const valueSet = new Set(value)
        return _.sortBy(_.range(items.length), (i) => !valueSet.has(items[i].value))
    }, [items, value])

    const handleSelect = clearOnSelect
        ? (v: string[]) => {
              setQuery("")
              onChange(v)
          }
        : onChange

    return (
        <Flex direction="column" gap="xs" {...others}>
            {showSearch && (
                <SearchInput
                    placeholder={placeholder || "Search items..."}
                    value={query}
                    onChange={setQuery}
                    rightSection={null}
                />
            )}
            {!singleSelect && (
                <Group>
                    <Anchor
                        onClick={() =>
                            handleSelect(
                                (itemResultIndices ?? selectedFirstIndices).map(
                                    (i) => items[i].value,
                                ),
                            )
                        }
                    >
                        Select {itemResultIndices ? "Results" : "All"}
                    </Anchor>
                    {!!value.length && <Anchor onClick={() => handleSelect([])}>Clear All</Anchor>}
                </Group>
            )}

            <Box style={{ flex: 1 }}>
                {singleSelect ? (
                    <Radio.Group
                        value={value[0] ?? null}
                        onChange={(value) => handleSelect([value])}
                    >
                        <SearchResults
                            key={query}
                            items={items}
                            resultIndices={itemResultIndices}
                            allIndices={showSearch ? selectedFirstIndices : null}
                            itemComponent={Radio}
                        />
                    </Radio.Group>
                ) : (
                    <Checkbox.Group value={value} onChange={handleSelect}>
                        <SearchResults
                            key={query}
                            items={items}
                            resultIndices={itemResultIndices}
                            allIndices={showSearch ? selectedFirstIndices : null}
                            itemComponent={(item) => (
                                <Checkbox
                                    {...item}
                                    wrapperProps={{
                                        style: { cursor: "pointer" },
                                        onMouseDown: (event: any) => {
                                            const element = event.target as HTMLInputElement
                                            element
                                                .closest(".mantine-Checkbox-root")
                                                ?.animate([{ opacity: 0 }], {
                                                    duration: 150,
                                                })
                                        },
                                    }}
                                />
                            )}
                        />
                    </Checkbox.Group>
                )}
            </Box>
        </Flex>
    )
}

export default SearchSelect
