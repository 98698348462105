import {
    Chart as ChartJS,
    ChartConfiguration as ChartJSConfig,
    defaults as ChartJSDefaults,
    Tooltip,
} from "chart.js"
import _ from "lodash"
import { dt } from "../../dfi-utils"
import {
    CHART_HEIGHT,
    CHART_WIDTH,
    DEFAULT_FOOTNOTE,
    LABEL_FONT_SIZE,
    LABEL_WIDTH,
    defaultTimeCol,
} from "./utils"

export const ADMIN_TOOL_PATH = process.env.REACT_APP_ADMIN_BASE

Tooltip.positioners.pointer = function (elements, eventPosition) {
    const tooltip = this
    const alignLeft = tooltip.chart.width / 2 > eventPosition.x
    var offset = alignLeft ? 10 : -10

    return {
        x: eventPosition.x + offset,
        y: eventPosition.y,
        xAlign: alignLeft ? "left" : "right",
    }
}

const tooltipDefaults = {
    backgroundColor: "rgba(255,255,255,0.9)",
    titleColor: "#555",
    bodyColor: "#555",
    borderColor: "#eee",
    borderWidth: 0.5,
    caretSize: 0,
    position: "pointer",
}

ChartJSDefaults.font.family = "Roboto"
ChartJSDefaults.font.size = LABEL_FONT_SIZE
ChartJSDefaults.color = "#555"
ChartJSDefaults.scales.linear.ticks.font = { size: LABEL_FONT_SIZE }
ChartJSDefaults.scales.category.ticks.font = { size: LABEL_FONT_SIZE }
ChartJSDefaults.scales.logarithmic.ticks.font = { size: LABEL_FONT_SIZE }
Object.assign(ChartJS.defaults.plugins.tooltip, tooltipDefaults)

export enum ChartType {
    BarChart = "bar",
    LineChart = "line",
    ScatterPlot = "scatter",
    Marimekko = "marimekko",
    Dotplot = "dotplot",
    Map = "map",
}

export enum BasicControls {
    chartType = "chartType",
    chartOptions = "chartOptions",
}

export enum ColumnControls {
    dimensions = "dimensions",
    time = "time",
    tiles = "tiles",
    xAxis = "xAxis",
    yAxis = "yAxis",
    size = "size",
    sort = "sort",
    map = "map",
}

export enum AxesControls {
    // fill = "fill",
    logscale = "logscale",
    stack = "stack",
    relative = "relative",
    multiplier = "multiplier",
    displayUnit = "displayUnit",
    position = "position",
}

export const timeSeriesCharts = [
    "BarChart",
    "ScatterPlot",
    "LineChart",
    "Marimekko",
    "Dotplot",
    "Map",
]
export const nonTimeSeriesCharts = ["BarChart", "ScatterPlot", "Marimekko", "Dotplot", "Map"]

export interface TimeColumn {
    codes: string[]
    min_time: number | null
    max_time: number | null
    display: boolean
}

export interface AxisBaseConfig {
    type: "dimension" | "indicator"
    codes: string[]
    selected: string[]
    label?: string
    locked?: boolean
    singleSelect?: boolean
    info: Record<string, { label?: string | null; color?: string | null; highlighted?: boolean }>
}

export interface DimensionsConfig extends AxisBaseConfig {
    customSort?: number[] | null
    axislabels?: boolean
}

export interface AxisConfig extends AxisBaseConfig {
    logscale?: boolean
    min?: number
    max?: number
    unit?: string
    multiplier?: number
    grouped?: "discrete" | "stack" | "overlap"
    relative?: "absolute" | "relative"
    transform?: "absolute" | "change" | "growth" | "cumulative"
    position?: "top" | "bottom"
    align?: boolean
    precision?: number
    prefix?: boolean
    space?: boolean
    hideInLabel?: boolean
    hideInTable?: boolean
    hideGridlines?: boolean
    hideAxislabels?: boolean
}

export function createAxis(
    codes: string[],
    type: "dimension" | "indicator" = "indicator",
): AxisBaseConfig {
    return { type, codes, selected: type == "dimension" ? [] : codes, info: {} }
}

export interface ChartConfig {
    //ChartControlsConfig
    type: ChartType
    palette: string
    chartjs: ChartJSConfig
    datalabels: boolean
    tiles: AxisBaseConfig
    dimensions: DimensionsConfig
    time: TimeColumn
    indicators: string[]
    colors?: AxisBaseConfig
    yAxis: AxisConfig
    xAxis?: AxisConfig
    size?: AxisConfig
    sort: {
        codes: string[] | null
        order: ("asc" | "desc")[]
    }
    choropleth?: {
        codes: string[] | null
    }
    text: { title: string; subtitle: string; footer: string; notes: string; sources: string }
    width: number
    height: number
    legend: boolean
    labelwidth: number
    tags?: string[]
    numcols: number
    projectionStart?: number
    categorylabels?: boolean
}

export function getTilesPerRow(type: ChartType): number {
    return type === ChartType.LineChart || type === ChartType.ScatterPlot ? 2 : 1
}

// container object to hold chart config and chart id
export interface DFIChartConfig {
    code?: string | null
    chart: ChartConfig
}

const defaultChartJSConfig: ChartJSConfig = {
    type: ChartType.BarChart,
    options: {
        indexAxis: "y",
        maintainAspectRatio: false,
        responsive: true,
    },
    data: {
        datasets: [],
    },
}

function splitTimeDimension(table: dt.DFIDataTable): [string[], string[]] {
    return _.partition(table.index, (x) => dt.getLabel(table, x).toLowerCase() != defaultTimeCol)
}

const defaultConfigFromTable = (table: dt.DFIDataTable): ChartConfig => {
    const [dim_codes, time_codes] = splitTimeDimension(table)
    const ind_codes = Object.keys(table.data).filter((x) => !table.index?.includes(x))
    return {
        type: ChartType.BarChart,
        palette: "default",
        chartjs: defaultChartJSConfig,
        datalabels: true,
        dimensions: {
            type: "dimension",
            codes: dim_codes,
            selected: [],
            info: {},
            locked: false,
        },
        tiles: {
            type: "dimension",
            codes: [],
            selected: [],
            info: {},
            locked: false,
        },
        time: {
            codes: time_codes,
            max_time: null,
            min_time: null,
            display: true,
        },
        indicators: ind_codes,
        yAxis: {
            type: "indicator",
            codes: ind_codes,
            selected: ind_codes,
            grouped: "discrete",
            relative: "absolute",
            transform: "absolute",
            logscale: false,
            info: {},
            locked: false,
            align: false,
            hideAxislabels: false,
            hideGridlines: false,
            hideInTable: false,
        },
        sort: {
            codes: null,
            order: [],
        },
        choropleth: {
            codes: null,
        },
        text: {
            title: table.title ?? "",
            subtitle: "",
            footer: DEFAULT_FOOTNOTE,
            notes: "",
            sources: "",
        },
        width: CHART_WIDTH,
        height: CHART_HEIGHT,
        legend: true,
        labelwidth: LABEL_WIDTH,
        tags: [],
        numcols: 1,
        categorylabels: true,
    }
}

export { defaultConfigFromTable }
