import { Box, Button, Group, Overlay, Text } from "@mantine/core"
import { IconEdit, IconX } from "@tabler/icons-react"
import _ from "lodash"
import { SearchSelect, useShallowMemo, utils } from "../../../dfi-utils"
import { ChartType } from "../config"
import { tileIndicatorsCode } from "../utils"
import { useDataContext } from "./chart-data-context"
import { useChart } from "./context"

export type OverlayType = "dimensions" | "indicators" | "tiles"

const ChartOverlayOptions: React.FC<{
    onClick: (overlay: OverlayType) => void
}> = ({ onClick }) => {
    const { form } = useChart()
    const { yAxis, dimensions, tiles } = form.values.chart
    const dctx = useDataContext()

    const sections: Record<OverlayType, { title: string; display: boolean }> = {
        tiles: {
            title: tiles.label || "Tiles",
            display: dctx.tiles.nunique > 1 && !tiles.locked,
        },
        dimensions: {
            title: dimensions.label || "Dimensions",
            display: dctx.dimensions.nunique > 1 && !dimensions.locked,
        },
        indicators: {
            title: yAxis.label || "Indicators",
            display: yAxis.codes.length > 1 && !yAxis.locked,
        },
    }

    return (
        <Group>
            {_.map(
                sections,
                (section, key) =>
                    section.display && (
                        <Button
                            compact
                            variant="line"
                            key={key}
                            onClick={() => onClick(key as OverlayType)}
                            rightIcon={<IconEdit size="1em" />}
                        >
                            {`Select ${section.title}`}
                        </Button>
                    ),
            )}
        </Group>
    )
}

export interface ChartOverlaySection {
    title: string
    key: string
    items: { value: string; label: string }[]
    formField: string
    singleSelect?: boolean
    onChange?: () => void
}

interface ChartOverlayDisplayProps {
    overlay: OverlayType
    onClose: () => void
}

export const ChartOverlayDisplay: React.FC<ChartOverlayDisplayProps> = ({ overlay, onClose }) => {
    const { form } = useChart()
    const { chart } = form.values
    const { yAxis, dimensions, tiles, type } = chart
    const dctx = useDataContext()
    const isScatter = type === ChartType.ScatterPlot
    const singleSelect = overlay === "indicators" ? yAxis.singleSelect : chart[overlay].singleSelect

    const overlayData = useShallowMemo<ChartOverlaySection>(() => {
        switch (overlay) {
            case "dimensions":
            case "tiles":
                const options = Array.from(dctx[overlay].value_labels.keys()).map((d) => ({
                    value: d,
                    label: dctx.getDisplayLabel(overlay, d),
                }))
                return {
                    title: chart[overlay].label || utils.titlecase(overlay),
                    key: chart[overlay].codes.join(":"),
                    items: options,
                    formField: `chart.${overlay}.selected`,
                    singleSelect: chart[overlay].singleSelect,
                    onChange: () => {
                        if (overlay === "dimensions") chart[overlay].customSort = null
                    },
                }

            case "indicators":
                const indOptions = yAxis.codes.map((c) => ({
                    value: c,
                    label: dctx.getDisplayLabel("yAxis", c),
                }))
                return {
                    title: yAxis.label || utils.titlecase(overlay),
                    key: yAxis.codes.join(":"),
                    items: indOptions,
                    formField: "chart.yAxis.selected",
                    singleSelect:
                        chart.yAxis.singleSelect ||
                        (isScatter && !tiles.codes.includes(tileIndicatorsCode)),
                }
        }
    }, [yAxis.codes, dimensions.codes, tiles.codes, singleSelect, overlay])

    const formProps = form.getInputProps(overlayData.formField)

    return (
        <Overlay opacity={0.5} key={overlayData.key} style={{ overflow: "hidden" }}>
            <Box
                p="md"
                h="calc(100% - 40px)"
                w={400}
                maw={"calc(100% - 40px)"}
                bg="white"
                my={20}
                mx="auto"
                style={{ border: "1px solid #ddd", overflow: "auto" }}
            >
                <Group position="apart" align="center" noWrap>
                    <Text fw={500}>{`Select ${overlayData.title}`}</Text>
                    <Button compact variant="light" onClick={() => onClose()}>
                        <IconX size="1em" />
                    </Button>
                </Group>
                <SearchSelect
                    mt={5}
                    clearOnSelect
                    items={overlayData.items}
                    value={formProps.value}
                    onChange={(val) => {
                        overlayData.onChange && overlayData.onChange()
                        formProps.onChange(val)
                        overlayData.singleSelect && onClose()
                    }}
                    placeholder={`Search ${overlayData.title}...`}
                    singleSelect={overlayData.singleSelect}
                    searchThreshold={5}
                />
            </Box>
        </Overlay>
    )
}

export default ChartOverlayOptions
