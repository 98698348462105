import { ActiveElement, Chart, ChartEvent } from "chart.js"
import * as colors from "../colors"
import { ChartType } from "../config"

export function hoverPlugin(
    event: ChartEvent,
    elements: ActiveElement[],
    chart: Chart,
    type?: ChartType,
) {
    if (type === ChartType.ScatterPlot) return
    if (elements.length) {
        const datasetIndex = elements[0]?.datasetIndex
        highlightDataset(chart, datasetIndex, type)
    } else {
        resetColors(chart, type)
    }
}

function highlightDataset(chart: any, datasetIndex: number, type?: ChartType) {
    chart.data.datasets.forEach((dataset: any, index: number) => {
        if (dataset.color) {
            const color =
                index === datasetIndex
                    ? colors.updateOpacity(dataset.color)
                    : colors.desaturate(dataset.color)
            dataset.backgroundColor = color
            if (type === ChartType.LineChart) {
                // in stacked line charts, colors overlap and create new colors
                // to avoid it, we set the opacity to 1
                if (dataset.fill) dataset.backgroundColor = colors.updateOpacity(color, 1)
                else dataset.order = index === datasetIndex ? 1 : 2
                dataset.borderColor = color
            }
        }
    })
    chart.update()
    if (type === ChartType.LineChart) {
        updateLabels(chart, datasetIndex)
    }
}

function resetColors(chart: any, type?: ChartType) {
    chart.data.datasets.forEach((dataset: any, index: number) => {
        if (dataset.color) {
            const color = colors.updateOpacity(dataset.color)
            dataset.backgroundColor = color
            if (type === ChartType.LineChart) {
                if (dataset.fill) dataset.backgroundColor = colors.updateOpacity(color, 1)
                else dataset.order = 1
                dataset.borderColor = color
            }
        }
    })
    chart.update()
}

export function resetChart(type?: ChartType, delay: number = 50) {
    if (type === ChartType.ScatterPlot) return
    setTimeout(() => {
        const allCharts = Chart.instances
        Object.values(allCharts).forEach((chart: Chart) => {
            resetColors(chart, type)
        })
    }, delay)
}

export function highlightLegendData(datasetIndex: number, type?: ChartType) {
    if (type === ChartType.ScatterPlot) return
    const allCharts = Chart.instances
    Object.values(allCharts).forEach((chart: Chart) => {
        highlightDataset(chart, datasetIndex, type)
    })
}

function updateLabels(chart: any, datasetIndex: number) {
    if (!chart.$datalabels.labels.length) return
    chart.data.datasets.forEach((dataset: any, index: number) => {
        const active = index === datasetIndex
        const color = active ? dataset.color : colors.desaturate(dataset.color)
        const label = chart.$datalabels.labels[index]
        if (label) {
            label.opacity = active ? 0.9 : 0.2
            label.color = color
        }
    })
}
